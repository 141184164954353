import { Box, Typography, Button, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import React from 'react';

const StyledText = ({ text }) => {
  const parts = text.split(/(\*\*.*?\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return (
        <Box
          key={index}
          component="span"
          sx={{
            fontSize: '1.2rem',
            fontWeight: 700,
            color: '#FFFFFF',
            textTransform: 'uppercase',
          }}
        >
          {part.slice(2, -2)}
        </Box>
      );
    }
    return <React.Fragment key={index}>{part}</React.Fragment>;
  });
};

export const CTA = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Box sx={{ bgcolor: '#8B7355', py: 12 }}>
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontWeight: 800, mb: 4, color: '#F5F5F1', fontSize: { xs: '2.5rem', md: '3.5rem' } }}>
          {data.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 6, fontSize: '1.1rem', lineHeight: 1.6, color: '#F5F5F1' }}>
          <StyledText text={data.description} />
        </Typography>
        <Button 
          variant="contained" 
          size="large" 
          endIcon={<ArrowForwardIcon />}
          startIcon={<AutoAwesomeIcon />}
          sx={{
            bgcolor: '#F5F5F1',
            color: '#2C1810',
            borderRadius: '50px',
            padding: '16px 32px',
            fontSize: '1.1rem',
            fontWeight: 600,
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 25px rgba(0,0,0,0.2)',
              bgcolor: 'rgba(245,245,241,0.8)',
            },
          }}
        >
          {data.buttonText || 'Get Started'}
        </Button>
      </Container>
    </Box>
  );
};