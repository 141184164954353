import { useState } from 'react';
import { Box, Container, Typography, Button, Stack, Avatar, AvatarGroup } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export const HeroSection = ({ data, statistics }) => {
  const designStyles = data.designStyles.map((style, index) => ({
    ...style,
    image: data[`demoScreen${index + 1}`]
  }));

  const [selectedStyle, setSelectedStyle] = useState(designStyles[0]);

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        pt: { xs: 4, md: 8 },
        pb: { xs: 4, md: 8 },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 4,
          alignItems: 'center'
        }}
      >
        {/* Left Column */}
        <Box>
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'text.secondary',
              display: 'block',
              mb: 2,
              fontSize: { xs: '1rem', md: '1.2rem' },
            }}
          >
            {data.subtitle}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.5rem', md: '5rem' },
              fontWeight: 800,
              letterSpacing: '0.02em',
              lineHeight: 1.2,
              mb: 3,
            }}
          >
            {data.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: 'text.secondary',
              mb: 4,
              maxWidth: '80%',
            }}
          >
            {data.description}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="contained"
              startIcon={<AutoAwesomeIcon />}
              sx={{
                bgcolor: 'black',
                color: 'white',
                borderRadius: '100px',
                px: 4,
                py: 1.5,
                '&:hover': {
                  bgcolor: 'rgba(0,0,0,0.8)',
                },
                textTransform: 'none',
              }}
            >
              {data.buttonText}
            </Button>
            <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
              <Avatar alt="Happy Customer" src="/avatar1.jpg" />
              <Avatar alt="Happy Customer" src="/avatar2.jpg" />
              <Avatar alt="Happy Customer" src="/avatar3.jpg" />
              <Avatar alt="Happy Customer" src="/avatar4.jpg" />
            </AvatarGroup>
            <Typography variant="body2" color="text.secondary">
              {data.customers}+
              <Typography component="span" variant="body2" color="text.secondary" sx={{ display: 'block', fontSize: '0.75rem' }}>
                {data.customerText}
              </Typography>
            </Typography>
          </Stack>
        </Box>

        {/* Right Column */}
        <Box
          sx={{
            position: 'relative',
            borderRadius: '24px',
            overflow: 'hidden',
            bgcolor: '#F5F5F5',
            p: 3,
            display: 'flex',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={selectedStyle.image}
              alt="AI-generert Interiørdesign"
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: '20px',
                objectFit: 'cover',
              }}
            />
          </Box>
          <Stack
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ml: 10,
            }}
          >
            <Stack spacing={2}>
              {designStyles.map((style, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => setSelectedStyle(style)}
                  sx={{
                    p: 2,
                    borderRadius: '16px',
                    bgcolor: selectedStyle.title === style.title ? 'rgba(0,0,0,0.05)' : 'white',
                    '&:hover': {
                      bgcolor: 'rgba(0,0,0,0.02)',
                    },
                    cursor: 'pointer',
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                      component="img"
                      src={style.image}
                      alt={style.title}
                      sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '32px',
                        objectFit: 'cover',
                        boxShadow: '1px 2px 2px rgba(0,0,0,0.5)'
                      }}
                    />
                    <Box>
                      <Typography variant="body2" fontWeight={500} color="black">
                        {style.title}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {style.caption}
                      </Typography>
                    </Box>
                  </Stack>
                  <ArrowForwardIcon sx={{ color: 'text.secondary' }} />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};