import { Box } from '@mui/material'
import { useState } from 'react'
import stylesData from 'data/styles.json'
import { AIDesignSection } from './sections/designSection'
import { HeroSection } from './sections/heroSection'
import { Statistics } from './sections/statisticsSection'
import { HowItWorks } from './sections/hiwSection'
import { DesignStyles } from './sections/stylesSection'
import { CTA } from './sections/ctaSection'

export const SplashScreen = () => {
  const [data] = useState(stylesData);

  return (
    <Box sx={{ bgcolor: '#F5F5F1', pt: '64px' }}>
      <HeroSection data={data.heroSection} statistics={data.statistics} />
      <AIDesignSection data={data.aiDesignSection} />
      <Statistics data={data.statistics || []} />
      <HowItWorks data={data.howItWorksSection || {}} />
      <DesignStyles data={data.designStyles || {}} />
      <CTA data={data.callToAction || {}} />
    </Box>
  );
};