import { Box, Typography, Button, Grid, Card, CardMedia, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

export const HowItWorks = ({ data }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/faq');
  };

  return (
    <Container maxWidth="lg" sx={{ my: 12 }}>
      <Card sx={{ borderRadius: '30px', overflow: 'hidden', backgroundColor: '#F5F5F1', boxShadow: 'none' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ position: 'relative', height: '100%', minHeight: 400, overflow: 'hidden' }}>
              <CardMedia 
                component="img" 
                image={data.image} 
                alt={data.imageAlt}
                sx={{ 
                  height: '100%', 
                  borderRadius: '30px', 
                  objectFit: 'cover',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  }
                }} 
              />
              <Box sx={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%', 
                background: 'linear-gradient(45deg, rgba(139, 115, 85, 0.1), transparent)' 
              }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: { xs: 4, md: 6 }, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="overline" sx={{ color: '#8B7355', letterSpacing: 2, mb: 1 }}>
                {data.subtitle}
              </Typography>
              <Typography variant="h3" sx={{ fontWeight: 700, mb: 2 }}>
                {data.title}
              </Typography>
              <Typography variant="body1" sx={{color: '#8B7355', mb: 4 }}>
                {data.description}
              </Typography>
              <Button 
                variant="outlined"
                endIcon={<ArrowForwardIcon />} 
                sx={{ 
                  alignSelf: 'flex-start',
                  transition: 'all 0.3s ease-in-out',
                  color: '#8B7355',
                  borderColor: '#8B7355',
                  '&:hover': {
                    transform: 'translateX(5px)',
                    color: '#8B7355',
                    borderColor: '#8b7355',
                  }
                }}
                onClick={handleButtonClick}
              >
                {data.buttonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
