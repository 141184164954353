import { Grid, Card, Typography, Container } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import GroupsIcon from '@mui/icons-material/Groups';
import React from 'react';

export const Statistics = ({ data }) => {
  const icons = [AutoAwesomeIcon, PeopleOutlineIcon, DesignServicesIcon, GroupsIcon];

  if (!Array.isArray(data) || data.length === 0) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ my: 12 }}>
      <Grid container spacing={3}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              p: 3, 
              borderRadius: '16px', 
              bgcolor: '#ffffff',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
              }
            }}>
              {React.createElement(icons[index % icons.length], { sx: { fontSize: 40, color: '#8B7355', mb: 2 } })}
              <Typography variant="h4" component="div" sx={{ fontWeight: 700, mb: 1 }}>
                {item.number}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600, color: '#2C1810', textAlign: 'center' }}>
                {item.label}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                {item.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};