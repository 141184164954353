import { CustomStack } from 'components/CustomStack';
import { AppWrapper } from 'components/AppWrapper';
import { AppRoutes } from 'components/AppRoutes';

export const App = () => {
	return (
		<AppWrapper>
					<AppRoutes/>
		</AppWrapper>
	);
};
