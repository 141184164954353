import { useState } from 'react';
import { Box, Typography, Grid, Card, CardMedia, Container } from '@mui/material';

export const DesignStyles = ({ data }) => {
  const [hoveredCard, setHoveredCard] = useState(null);

  if (!data || !data.styles || !Array.isArray(data.styles)) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ my: 12, color: '#2C1810' }}>
      <Typography variant="h2" sx={{ fontWeight: 800, mb: 2, fontSize: { xs: '2.5rem', md: '3.5rem' } }}>
        {data.title || 'Our Design Styles'}
      </Typography>
      <Typography variant="body1" sx={{ mb: 6, maxWidth: '600px', fontSize: '1.1rem', lineHeight: 1.6 }}>
        {data.description || 'Explore our range of design styles tailored to your preferences.'}
      </Typography>
      <Grid container spacing={4}>
        {data.styles.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                borderRadius: '20px', 
                overflow: 'hidden', 
                position: 'relative',
                transition: 'all 0.3s ease-in-out',
                transform: hoveredCard === index ? 'scale(1.05)' : 'scale(1)',
                boxShadow: hoveredCard === index ? '0 10px 30px rgba(0,0,0,0.2)' : '0 4px 10px rgba(0,0,0,0.05)',
              }}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <CardMedia
                component="img"
                height="300"
                image={item.image}
                alt={item.name}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.54)',
                  color: '#ffffff',
                  padding: '10px',
                }}
              >
                <Typography variant="h6" sx={{ color: '#ffffff' }}>{item.name}</Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};